import * as THREE from 'three';
import {rand, randInt, randChoice, map, rad} from './util';
class Ornament {
    constructor({ scene, x, y, z=0, texture, font, index, numbers }) {
      this.scene = scene;
      this.phase = Math.random() * 20.0;
      const base = new THREE.Group();
      base.position.x = x;
      base.position.y = y;
      
      this.base = base;
      scene.add(base);
      this.length = -50 - rand(10);
      this.swingOffset = rand(10);
      const item = new THREE.Group();
      item.position.y = this.length;
      base.add(item);
      this.item = item;
      this.texture = texture;
  
      this.radius = 4 + rand(8);
  
      this.text = 1;
  
      this.font = font;
  
      this.material = new THREE.MeshPhongMaterial({
      //   map: this.texture,
          color:"#880000",
        shininess: 40,
        // opacity:0.5
      });
      this.fontMaterial = new THREE.MeshPhongMaterial({
      //   map: this.texture,
          color:"#ffffff",
          shininess: 120,
      });
      this.addItems();

      base.position.z = z;
    }
    addItems() {
      this.addBall();
      this.addLine();
      this.addCylynder();
      this.addRing();
      this.addText();
    }
    addBall() {
      const geometry = new THREE.SphereBufferGeometry(this.radius, 20, 20);
  
      const ball = new THREE.Mesh(geometry, this.material);
      this.item.add(ball);
    }
    addCylynder() {
      const geometry = new THREE.CylinderBufferGeometry(0.8, 0.8, 2, 15, 5);
  
      const mesh = new THREE.Mesh(geometry, this.material);
      mesh.position.x = 0;
      mesh.position.y = 4;
      this.item.add(mesh);
    }
    addRing() {
      const geometry = new THREE.TorusBufferGeometry(0.8, 0.2, 10, 24);
      const material = new THREE.MeshPhongMaterial({
        color: 0x393e46,
        shininess: 80,
      });
      const mesh = new THREE.Mesh(geometry, material);
      mesh.position.x = 0;
      mesh.position.y = 5.2;
      this.item.add(mesh);
    }
    addLine() {
      const material = new THREE.LineBasicMaterial({
        color: 0x666666,
      });
      const points = [
        new THREE.Vector3(0, 0, 0),
        new THREE.Vector3(0, this.length + 6, 0),
      ];
      const geometry = new THREE.BufferGeometry().setFromPoints(points);
      const line = new THREE.Line(geometry, material);
  
      this.base.add(line);
    }
  
    update(time) {
      const angle = map(Math.sin(time + this.phase ), -1, 1, -rad(45), rad(45));
      this.item.rotation.y = angle * 3;
      this.base.rotation.z = angle;
    //   console.log('time',time);

      this.base.position.z += 1;
      if(this.base.position.z > 50) {
          this.base.position.z -= Ornament.OrnamentCount *300;
      }
    }
    addText() {
      const textHeight = 0.4;
      const fontSize = 2.2;
      let rowCount = Math.round(this.radius / (fontSize));

       
      for( let row=0; row<rowCount;row++){
        let numberCount = 5;
        for(let i=0; i<numberCount; i++){
            const text = ""+randInt(99)+"";
            const geometry = new THREE.TextGeometry(text, {
            font: this.font,
            size: fontSize,
            height: 0.4,
            curveSegments: 12,
            });
    
            const mesh = new THREE.Mesh(geometry, this.fontMaterial);
    
            
            const period = i * (Math.PI * 2.0 / numberCount) + Math.PI/(row*row) ;
            const centerVector = new THREE.Vector3(this.item.position);
            centerVector.sub(mesh.position) 
            centerVector.normalize();

            mesh.rotation.y = period;
            // mesh.lookAt(centerVector);
            mesh.position.z = Math.cos(period) * this.radius;
            mesh.position.x = Math.sin(period) * this.radius;
            mesh.position.y =  2 * row * (this.radius/(rowCount+1)) - this.radius ;

            // rotate it

            this.item.add(mesh);
        }

        }
      
    }
  }

  export default Ornament;