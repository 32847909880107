

export const rand = (max:number, min:number = 0) : number => min + Math.random() * (max - min);
export const randInt = (max, min = 0) => Math.floor(min + Math.random() * (max - min));
export const randChoice = (arr) => arr[randInt(arr.length)];

export const map = (value, sMin, sMax, dMin, dMax) => {
    return dMin + ((value - sMin) / (sMax - sMin)) * (dMax - dMin);
  };


export const range = (n, m = 0) =>
  Array(n)
    .fill(m)
    .map((i, j) => i + j);

// https://github.com/bit101/CodingMath
export const bez = (p0, p1, p2, t) => {
  const x = Math.pow(1 - t, 2) * p0.x + (1 - t) * 2 * t * p1.x + t * t * p2.x;
  const y = Math.pow(1 - t, 2) * p0.y + (1 - t) * 2 * t * p1.y + t * t * p2.y;
  return [x, y];
};

export const rad = (deg) => (deg / 180) * Math.PI;