import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import Ornament from './ornament';
import {rand, randInt, randChoice, map, bez, range} from './util';

window.THREE = THREE;
let camera, controls, scene, renderer, loader, textures, font;
let ornaments = [];



function loadItems() {
  const loader = new THREE.FontLoader();
  loader.load("https://assets.codepen.io/3685267/droid_sans_bold.typeface.json", function (fontx) {
    font = fontx;
    init();
    animate();
  });
}
loadItems();

function getY(x) {
  const xActual = x + 50;
  const t = map(xActual % 20, 0, 20, 0, 1);
  const [_, y] = bez({ x: 0, y: 0 }, { x: 10, y: -8 }, { x: 20, y: 0 }, t);
  return y;
}

function loadTextures() {
  textures = range(9).map((i) => {
    const texture = loader.load(`https://assets.codepen.io/3685267/christmas_texture_${i}.jpg`);

    return texture;
  });
}

function addOrnaments(num, posY) {
  range(num).forEach((i) => {
    if (i) {
      const x = 0
      const y = 100;

      ornaments.push(
        new Ornament({
          scene,
          x,
          y,
          z: ornaments.length * -300,
          texture: randChoice(textures),
          font,
          index: ornaments.length,
          numbers:[range(20).map(()=>Math.random())]
        })
      );
    }
  });
}

function init() {
  scene = new THREE.Scene();
  scene.position.y += 13;
  loader = new THREE.TextureLoader();
  renderer = new THREE.WebGLRenderer({ antialias: true, alpha:true });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);
  document.body.appendChild(renderer.domElement);
  camera = new THREE.PerspectiveCamera(
    60,
    window.innerWidth / window.innerHeight,
    1,
    1000
  );
  camera.position.set(0, 0, 110);
  controls = new OrbitControls(camera, renderer.domElement);
  loadTextures();
  [
    [3, 9],
    [0, 9],
    [-3, 10],
  ].forEach(([row, num]) => {
    // addTube(row);
    addOrnaments(num, row * 10);
  });

  addLights();
  const color = 0x000000;  // white
  const near = 0;
  const far = 1000;
  Ornament.OrnamentCount = ornaments.length;
//   scene.fog = new THREE.Fog(color, near, far);
//   addPlane();
  window.addEventListener("resize", onWindowResize, false);
}

function onWindowResize() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
}

function animate(time) {
  requestAnimationFrame(animate);
  ornaments.forEach((item) => {
    item.update(time * 0.001);
  });
  renderer.render(scene, camera);
}

function addTube(yRoot = 0) {
  class CustomSinCurve extends THREE.Curve {
    constructor(scale = 1) {
      super();

      this.scale = scale;
    }

    getPoint(t, optionalTarget = new THREE.Vector3()) {
      const x = map(t, 0, 1, 0, 10);
      const a = map(x % 2, 0, 2, 0, 1);
      const [_, y] = bez({ x: 0, y: 0 }, { x: 1, y: -0.8 }, { x: 2, y: 0 }, a);
      const z = 0;
      return optionalTarget.set(x - 5, y + yRoot, z).multiplyScalar(this.scale);
    }
  }
  const texture = loader.load("https://assets.codepen.io/3685267/christmas_texture_9.jpg");

  const path = new CustomSinCurve(10);
  const geometry = new THREE.TubeGeometry(path, 100, 1, 16, false);
  texture.wrapT = THREE.RepeatWrapping;
  texture.wrapS = THREE.RepeatWrapping;
  texture.repeat.x = 10;
  texture.repeat.y = 1;
  texture.offset.set(0.5, 0.5);
  const material = new THREE.MeshPhongMaterial({
    map: texture,
    shininess: 120,
  });
  const mesh = new THREE.Mesh(geometry, material);
  scene.add(mesh);
}

function addLights() {
  let color = 0xffffff;
  let intensity = 0.9;
  let light = new THREE.DirectionalLight(color, intensity);
  light.position.set(0, 0, 80);
  scene.add(light);

   color = 0x8899ff;
   intensity = 0.9;
  light = new THREE.DirectionalLight(color, intensity);
  light.position.set(50, 1000, -1000);
  scene.add(light);
}

function addPlane() {
  const geometry = new THREE.PlaneBufferGeometry(500, 500, 32);
  const material = new THREE.MeshPhongMaterial({
    color: 0x570b22,
    shininess: 10,
  });
  const plane = new THREE.Mesh(geometry, material);
  plane.position.z = -5;
  scene.add(plane);
}


